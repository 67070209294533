import { graphql } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Trans } from "gatsby-plugin-react-i18next";

import Layout from "../components/Layout";
import SidebarAccount from "../components/SidebarAccount";

const About = (props) => {
  const {
    region_data: { unique_region_text },
  } = props;

  return (
    <Layout pageName="about">
      <Helmet>
        <title>{unique_region_text?.siteTitle ?? ""}</title>
      </Helmet>
      <section className="section-container">
        <h1>
          <Trans>About</Trans>
        </h1>
        {unique_region_text?.siteDescription ?? ""}
      </section>
      <SidebarAccount {...props} />
    </Layout>
  );
};

export default connect((state) => state)(About);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
